<template>
  <div class="index_v1_container container">

    <b-row class="row index_header mb-3 ptb_3r">
      <b-col lg="5">
        <div style="height: 100%;" class="d-flex flex-column justify-content-center">
          <h1 class="right_box01 title">Pay with USDT</h1>
          <p>Purchase DaVinci Prepaid Cards with USDT(Trc20). Pay at millions of online merchants with secure virtual cards.</p>
          <div class="mb-3">
            <b-button variant="primary" size="lg" @click="toLogin">Get Started</b-button>
          </div>
        </div>
      </b-col>
      <b-col lg="7">
        <div class="img_positon">
          <img src="@/assets/index_v1/index_v1_img01.png" class="img01" alt="DaVinciCard">
        </div>
      </b-col>
    </b-row>
    <div class="text-center mb-3">
      <h4>Shop everywhere Visa cards are accepted<sup>2</sup>, including:</h4>
    </div>
    <b-row class="mb_3r">
      <b-col sm style="max-width: 50%;"><img src="@/assets/index_v1/pingtai/alipay.jpg" style="width: 100%;" /></b-col>
      <b-col sm style="max-width: 50%;"><img src="@/assets/index_v1/pingtai/meituan.jpg" style="width: 100%;transform: scale(0.8);" /></b-col>
      <b-col sm style="max-width: 50%;"><img src="@/assets/index_v1/pingtai/azure.png" style="width: 100%;transform: scale(0.75);margin-top: -0.25rem;" /></b-col>
      <b-col sm style="max-width: 50%;"><img src="@/assets/index_v1/pingtai/kraken.jpg" style="width: 100%;transform: scale(0.8);" /></b-col>
      <b-col sm style="max-width: 50%;"><img src="@/assets/index_v1/pingtai/racknerd.jpg" style="width: 100%;" /></b-col>
    </b-row>
    <b-row class="row mb_3r">
      <b-col md="6">
        <h2>How it Works</h2>
        <div class="how_it_works d-flex">
          <div>
            <div class="mb-3">
              <p>1. select Card bin</p>
            </div>
            <div class="mb-3">
              <p>2. Pay USDT (Trc20)</p>
            </div>
            <div class="mb-3">
              <p>3. Get your Davinci-card  </p>
            </div>
          </div>
          <div class="vertical-bar pl-3 ">
            <!-- <div class="d-block d-lg-none d-md-none d-sm-none"><img src="@/assets/index_v1/123.png" class="pr-1"/></div> -->
            <div class="rigth-bar"></div>
            <div class="left_bar"></div>
          </div>
        </div>
      </b-col>
      <b-col md="6"></b-col>
    </b-row>
    <b-row class="features-content ptb_3r">
      <b-col lg="4" class="d-flex mb-3">
        <img src="@/assets/index_v1/instant.svg" style="width: 4.25rem;height: 4.25rem;" />
        <div class="ml-2">
          <h5 class="mb-0">Private</h5>
          <p>DaVinci shields your personal information from merchants.</p>
        </div>
      </b-col>
      <b-col lg="4" class="d-flex mb-3">
        <img src="@/assets/index_v1/secure.svg" style="width: 4.25rem;height: 4.25rem;" />
        <div class="ml-2">
          <h5 class="mb-0">Secure</h5>
          <p>All data is encrypted and DaVinci is <u>not</u> a custodial service.</p>
        </div>
      </b-col>
      <b-col lg="4" class="d-flex mb-3">
        <img src="@/assets/index_v1/no-fees.svg" style="width: 4.25rem;height: 4.25rem;" />
        <div class="ml-2">
          <h5 class="mb-0">Low Fees</h5>
          <p>We charge you only low fees.</p>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb_3r">
      <b-col lg="5" offset-lg="1" md="8" offset-md="2" :order-md="1" :order-lg="2">
        <img src="@/assets/index_v1/vinci_card.png" style="width: 100%;" />
      </b-col>
      <b-col class="mt-3" lg="6" :order-md="2" :order-lg="1">
        <b-container>
          <h2>What is a Virtual Card?</h2>
          <p>A virtual card is a anonymous digital payment card that can be used to make online purchases.</p>
          <p>DaVinci Cards are virtual cards that can be loaded with USDT-Trc20.</p>
          <p>Crypto is converted to USD the moment you purchase a card and funds are immediately available to spend where you want.</p>
        </b-container>
      </b-col>
    </b-row>
    <b-row class="pl-3 pr-3 mb_3r">
      <b-col lg="5" offset-lg="0" md="8" offset-md="2">
        <img src="@/assets/index_v1/crypto-to-card.png" style="width: 100%;" />
      </b-col>
      <b-col lg="6" offset-lg="1" class="d-flex align-items-center" >
        <div class="pt-3">
          <h2>USDT, Oh My!</h2>
          <p>Purchase DaVinci cards with USDT directly from your USDT wallet.</p>
          <p>There are low trading fees, waiting times, or hurdles to prevent you from using your card.</p>
          <b-row>
            <b-col :cols="6" class="mt-1">
              <img src="@/assets/index_v1/center/biance.jpg" class="img-fluid ways-to-pay" />
            </b-col>
            <b-col :cols="6">
              <img src="@/assets/index_v1/center/imtoken.jpg" class="img-fluid ways-to-pay" />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <div class="text-center" style="padding-top: 3rem;">
      <h4>As Seen In</h4>
    </div>
    <b-container>
      <b-row class="align-items-center ptb_3r">
        <b-col cols="3" sm="2" offset-sm="1" >
          <img src="@/assets/index_v1/other/aws.jpeg" style="width: 100%;"/>
        </b-col>
        <b-col cols="4" sm="4" class="ml-3">
          <img src="@/assets/index_v1/other/godaddy.png" style="width: 100%;"/>
        </b-col>
        <b-col cols="4" sm="4">
          <img src="@/assets/index_v1/other/crunchbase.png" style="width: 100%;" />
        </b-col>
      </b-row>
    </b-container>
    <div class="text-center ptb_3r">
      <h3>Ready to Try DaVinci?</h3>
      <!-- <div class="d-flex justify-content-around pb_3r">
        <b-button variant="primary" size="lg" @click="toLogin">Get Started</b-button>
      </div> -->
    </div>

    <div class="text-center">
      <h4>Subscribe to Our Newsletter</h4>
    </div>
    <b-row class="email_subscribe pb_3r">
      <b-col lg="6" offset-lg="3" md="8" offset-md="2">
        <b-input-group>
          <b-form-input placeholder="Enter your email"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary">Subscribe</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        bgObj: {
          bg: require("@/assets/index_v1/index_v1_bg.png"),
          size: '50%',
        },
      }
    },
    created() {
      if (this.$store.state.user.token) {
        this.$router.replace({
          path: '/index'
        })
      }
      this.$emit('imageBg', this.bgObj)
    },
    methods: {
      toLogin() {
        this.$router.push({
          name: "login",
          params: {
            tag: '1'
          },
        })
      },
    }
  }
</script>

<style lang="scss">
  .index_v1_container {

    .pb_3r {
      padding-bottom: 3rem;
    }

    .ptb_3r {
      padding: 3rem 0;
    }

    .mb_3r {
      margin-bottom: 3rem;
    }

    .opacity_5 {
      opacity: 0.5;
    }

    .index_header {
      .img01 {
        width: 100%;
      }

      .right_box01 {
        &.title {
          display: flex;
          align-items: flex-start;

          span {
            font-size: 0.4em;
          }
        }

      }
    }

    .how_it_works {
      .vertical-bar {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .left_bar {
          width: 0.2rem;
          height: 100%;
          background-color: #343a40;
        }

        .rigth-bar {
          width: 0.2rem;
          height: 15%;
          background-color: $theme;
          border-radius: 0.1rem;
          margin: 2rem 0.1rem 0 0;
        }
      }

      p {
        margin-bottom: 0;
      }

    }

    .features-content {
      p {
        font-size: 12px;
      }
    }
    
    .email_subscribe {
      .input-group {
        border: 1px solid #ccc;
        border-radius: 2rem;
        overflow: hidden;
      }
      .btn {
        border-radius: 2rem;
        padding-left: 1.3rem;
        padding-right: 1.3rem;
      }
      input {
        border: 0 ;
      }
    }
    
    .img_positon {
      position: relative;
      
      .one {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 2rem;
      }
    }


  }
</style>
